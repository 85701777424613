import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Ring } from '@uiball/loaders';
import clsx from 'clsx';
import ReactTooltip from 'react-tooltip';
import ViewportList from 'react-viewport-list';
import { ReactComponent as SortDownIcon } from '../../assets/sort-down.svg';
import { ReactComponent as SortUpIcon } from '../../assets/sort-up.svg';
import Tooltip from '../../shared/components/tooltip/Tooltip';
import { useStats } from '../../shared/hooks/useStats';
import { StatsSorting, StatsSortingProp } from '../../shared/models';
import { useSelector } from '../../store/store';
import m from '../../styles/style/_shared.module.scss';
import p from '../pending-documents/pending-documents.module.scss';
import { workflowStates } from '../tenant-management/TenantGroup';
import s from './stats.module.scss';
import StatsRow from './StatsRow';
interface Props {}

const Stats: React.FC<Props> = (props) => {
  const user = useSelector((state) => state.auth.user);

  const [sorting, setSorting] = useState<StatsSorting>({
    prop: 'inflow',
    descending: false,
  });
  const [statsCount, setStatsCount] = useState(1);
  const { stats, loading } = useStats(statsCount);

  const listRef = useRef();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setStatsCount((prev) => prev + 1);
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const sortedStats = useMemo(() => {
    if (stats?.tenants) {
      let list = [...stats?.tenants].sort((a, b) => {
        let sortingValue;
        switch (sorting.prop) {
          case 'inflow': {
            sortingValue = b.inflow - a.inflow;
            break;
          }
          case 'automation': {
            sortingValue = b.automationPercent - a.automationPercent;
            break;
          }
          case 'activeUsers': {
            sortingValue = b.activeUsers - a.activeUsers;
            break;
          }
          case 'open': {
            sortingValue = b.open.total - a.open.total;
            break;
          }
          default:
            sortingValue = 0;
            break;
        }
        if (sorting.descending) sortingValue = sortingValue * -1;
        return sortingValue;
      });
      return list;
    } else {
      return [];
    }
  }, [stats, sorting]);

  const handleSetSorting = (prop: StatsSortingProp) => {
    if (sorting.prop === prop) {
      setSorting({ ...sorting, descending: !sorting.descending });
    } else {
      setSorting({ prop, descending: false });
    }
    if (listRef.current) {
      const list = listRef.current as HTMLDivElement;
      list.scrollTo({ top: 0 });
    }
  };
  const ref = useRef();

  return (
    <div className={m.container}>
      <Tooltip id={`stats-cloud`} text={'Go to tenant in GC'} />
      <Tooltip id={`stats-firestore`} text={'Go to tenant in Firestore'} />
      <Tooltip id={`stats-env`} text={"Go to the tenant's environment"} />
      <div className={m.page_header}>
        <h1 className={m.page_title}>Tenant Stats</h1>
      </div>

      <div className={s.stats}>
        <div className={s.stat}>
          <div className={s.stat_number}>{stats?.inflow ?? 0}</div>
          <span className={s.stat_text}>Document Inflow</span>
        </div>
        <div className={s.stat}>
          <div className={s.stat_number}>{stats?.automationPercent.toFixed(1) ?? 0}%</div>
          <span className={s.stat_text}>Automation Rate</span>
        </div>
        <div className={s.stat}>
          <div className={s.stat_number}>{stats?.activeUsers ?? 0}</div>
          <span className={s.stat_text}>Active Users</span>
        </div>
        <div className={s.stat}>
          <div className={s.stat_number}>{stats?.open ?? 0}</div>
          <span className={s.stat_text}>Open Documents</span>
        </div>
      </div>

      <div className={p.table}>
        <div className={p.header}>
          <div className={s.zero} />
          <button
            className={clsx(p.header_name, s.first)}
            onClick={() => handleSetSorting('name')}
          >
            <span>Name</span>
            {sorting.prop === 'name' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <button
            className={clsx(p.header_name, s.second)}
            onClick={() => handleSetSorting('inflow')}
          >
            <span>Inflow</span>
            {sorting.prop === 'inflow' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <button
            className={clsx(p.header_name, s.third)}
            onClick={() => handleSetSorting('automation')}
          >
            <span>Automation</span>
            {sorting.prop === 'automation' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <button
            className={clsx(p.header_name, s.fourth)}
            onClick={() => handleSetSorting('activeUsers')}
          >
            <span>Active Users</span>
            {sorting.prop === 'activeUsers' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <button
            className={clsx(p.header_name, s.fifth)}
            onClick={() => handleSetSorting('open')}
          >
            <span>Open Documents</span>
            {sorting.prop === 'open' &&
              (sorting.descending ? <SortUpIcon /> : <SortDownIcon />)}
          </button>
          <div className={s.sixth}>Actions</div>
        </div>
        <div ref={ref} className={p.body}>
          {loading && !stats ? (
            <div className={s.loader}>
              <Ring color={'#0085FF'} />
            </div>
          ) : (
            <ViewportList
              viewportRef={ref}
              items={sortedStats}
              onViewportIndexesChange={() => ReactTooltip.rebuild()}
            >
              {(stat) => {
                return (
                  <div
                    key={stat.id}
                    style={{
                      paddingBottom: 4,
                      paddingTop: 4,
                      width: '100%',
                    }}
                  >
                    <StatsRow statsTenant={stat} user={user} />
                  </div>
                );
              }}
            </ViewportList>
          )}
        </div>
      </div>
    </div>
  );
};

export default Stats;
