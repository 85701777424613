import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as ChevronIcon } from '../../assets/chevron.svg';
import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';
import ReprocessModal from '../../shared/components/modal/ReprocessModal';
import { Stats, Tenant } from '../../shared/models';
import { useSelector } from '../../store/store';
import st from '../stats/stats.module.scss';
import InboxRow from './InboxRow';
import s from './tenant-management.module.scss';

interface Props {
  tenant: Tenant;
  stats: Stats;
}

export enum workflowStates {
  'PROVISIONING' = 'linear-gradient(90deg, #0066CC, #3399FF)', // Gradient from dark to light blue
  'PENDING' = 'linear-gradient(90deg, #fd7f2c, #fda766)', // Gradient from medium to light grey
  'RUNNING' = 'linear-gradient(90deg, #2E7D32, #66BB6A)', // Gradient from dark to light green
  'FAILED' = 'linear-gradient(90deg, #D32F2F, #FF6B6B)', // Gradient from dark to light red
  'DEAD' = 'linear-gradient(90deg, #220707, #700404)', // Gradient from dark to light teal
}
const TenantGroup: React.FC<Props> = ({ tenant, stats }) => {
  const [isReprocessModalOpen, setIsReprocessModalOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [isOpen, setIsOpen] = useState(false);

  const tenantStats = useMemo(() => {
    if (stats) {
      return stats.tenants.find((e) => e.id === tenant.id);
    }
  }, [stats, tenant]);

  const tenantOpen = tenantStats?.open.total || 0;
  const totalOpen = stats?.open;

  const totalStatuses = tenantStats?.workflowStats.total
    ? Object.entries(tenantStats?.workflowStats.total).reduce(
        (sum, status) => (status[0] !== 'FINISHED' ? sum + status[1] : sum),
        0
      )
    : 0;

  return (
    <>
      {isReprocessModalOpen && (
        <ReprocessModal
          tenantId={tenant.id}
          inboxes={tenant.inboxes}
          isOpen={isReprocessModalOpen}
          setIsOpen={setIsReprocessModalOpen}
        />
      )}

      <div className={s.tenant_rows}>
        <div
          style={{ cursor: 'pointer' }}
          className={s.tenant_row}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          <div className={clsx(s.first)}>
            <b>{tenant.id}</b>
          </div>

          {totalStatuses > 0 && (
            <ReactTooltip
              id={`stats-tenant-${tenant.id}`}
              backgroundColor={'#fff'}
              arrowColor={'transparent'}
              place="bottom"
              effect="solid"
            >
              <div className={st.status_tooltip}>
                {Object.entries(tenantStats?.workflowStats.total || {}).map(
                  ([status, count]) => {
                    return (
                      <div key={status} className={st.status_row}>
                        <span
                          className={st.status_color}
                          style={{
                            background:
                              workflowStates[status as keyof typeof workflowStates],
                          }}
                        ></span>
                        <div>
                          <span className={st.status_label}>{status.toLowerCase()}</span>
                        </div>
                        <span className={st.status_count}>{count}</span>
                      </div>
                    );
                  }
                )}
              </div>
            </ReactTooltip>
          )}

          <div className={s.second}>
            <div
              data-tip
              data-for={`stats-tenant-${tenant.id}`}
              className={st.percentage_wrapper}
            >
              {Object.entries(workflowStates).map(([state, color]) => {
                const stat =
                  tenantStats?.workflowStats.total[
                    state as keyof typeof workflowStates
                  ] ?? 0;
                if (stat === 0) return null;
                return (
                  <div
                    key={state}
                    className={st.percentage_inner}
                    style={{
                      background: color,
                      width: `${(stat / totalStatuses) * 100 || 0}%`,
                      transition: 'width 0.5s ease-in-out',
                    }}
                  ></div>
                );
              })}
            </div>
            {totalStatuses}
          </div>

          <div className={s.second}>
            <div className={st.percentage_wrapper}>
              <div
                className={st.percentage_inner}
                style={{
                  width: `${(tenantOpen / totalOpen) * 100 || 0}%`,
                  transition: 'width 0.5s ease-in-out',
                }}
              ></div>
            </div>
            {tenantOpen ?? 0}
          </div>

          <div className={clsx(s.third)}>
            {user.role === 'superUser' && (
              <button onClick={() => setIsReprocessModalOpen(true)}>
                <ReloadIcon />
              </button>
            )}
          </div>

          <div className={s.fourth}>
            <ChevronIcon style={{ transform: `rotate(${isOpen ? 180 : 0}deg)` }} />
          </div>
        </div>

        {isOpen && (
          <>
            {tenant.inboxes.map((inbox) => {
              const inboxStats = tenantStats?.workflowStats.data[inbox.id];
              const inboxOpen = tenantStats?.open.data[inbox.id]?.open ?? 0;
              // return <div>x</div>;
              return (
                <InboxRow
                  tenantOpenDocs={tenantOpen ?? 0}
                  key={inbox.id}
                  inbox={inbox}
                  tenantId={tenant.id}
                  inboxOpen={inboxOpen}
                  statusData={(inboxStats?.workflowStats as any) ?? {}}
                />
              );
            })}
            <div className={s.row_bottom} />
          </>
        )}
      </div>
    </>
  );
};

export default TenantGroup;
