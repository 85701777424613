import React, { useState } from 'react';
import clsx from 'clsx';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as ReloadIcon } from '../../assets/reload.svg';
import ReprocessModal from '../../shared/components/modal/ReprocessModal';
import { Inbox, WorkflowStatusStats } from '../../shared/models';
import { useSelector } from '../../store/store';
import st from '../stats/stats.module.scss';
import s from './tenant-management.module.scss';
import { workflowStates } from './TenantGroup';

interface Props {
  tenantId: string;
  inbox: Inbox;
  statusData: WorkflowStatusStats;
  inboxOpen: number;
  tenantOpenDocs: number;
}

const InboxRow: React.FC<Props> = ({
  tenantId,
  inbox,
  statusData,
  inboxOpen,
  tenantOpenDocs,
}) => {
  const [isReprocessModalOpen, setIsReprocessModalOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const totalStatuses = statusData
    ? Object.entries(statusData).reduce(
        (sum, status) => (status[0] !== 'FINISHED' ? sum + status[1] : sum),
        0
      )
    : 0;

  return (
    <>
      {isReprocessModalOpen && (
        <ReprocessModal
          tenantId={tenantId}
          inboxes={[inbox]}
          isOpen={isReprocessModalOpen}
          setIsOpen={setIsReprocessModalOpen}
        />
      )}
      <div className={s.row}>
        <span className={clsx(s.title, s.first)}>{inbox.name}</span>
        {/* Tooltip showing all different statuses and their numbers */}
        {totalStatuses > 0 && (
          <ReactTooltip
            id={`stats-inbox-${inbox.id}`}
            backgroundColor={'#fff'}
            arrowColor={'transparent'}
            place="bottom"
            effect="solid"
          >
            <div className={st.status_tooltip}>
              {Object.entries(statusData || {}).map(([status, count]) => {
                console.log(totalStatuses);
                return (
                  <div key={status} className={st.status_row}>
                    <span
                      className={st.status_color}
                      style={{
                        background: workflowStates[status as keyof typeof workflowStates],
                      }}
                    ></span>
                    <div>
                      <span className={st.status_label}>{status.toLowerCase()}</span>
                    </div>
                    <span className={st.status_count}>{count}</span>
                  </div>
                );
              })}
            </div>
          </ReactTooltip>
        )}

        <div className={s.second}>
          <div
            data-tip
            data-for={`stats-inbox-${inbox.id}`}
            className={st.percentage_wrapper}
          >
            {Object.entries(workflowStates).map(([et, v]) => {
              var stat = statusData[et as keyof typeof workflowStates] ?? 0;
              return (
                <div
                  className={st.percentage_inner}
                  style={{
                    background: v,
                    width: `${(stat / totalStatuses) * 100 || 0}%`,
                    transition: 'width 0.5s ease-in-out',
                  }}
                ></div>
              );
            })}
          </div>
          {totalStatuses}
        </div>
        <div className={s.second}>
          <div className={st.percentage_wrapper}>
            <div
              className={st.percentage_inner}
              style={{
                width: `${(inboxOpen / tenantOpenDocs) * 100 || 0}%`,
                transition: 'width 0.5s ease-in-out',
              }}
            ></div>
          </div>
          {inboxOpen ?? 0}
        </div>
        <div className={s.third}>
          {user.role === 'superUser' && (
            <button onClick={() => setIsReprocessModalOpen(true)}>
              <ReloadIcon />
            </button>
          )}
        </div>
        <div className={s.fourth} />
      </div>
    </>
  );
};

export default InboxRow;
